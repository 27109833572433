<template>
  <div id="visa">
    <section
      class="page-header"
      style="background-image: url('/imgs/visa-01.png')"
    >
      <div class="caption">
        <h4>{{ $lang("title") }}</h4>
        <i class="isologo"></i>
        <h1>{{ $lang("subtitle") }}</h1>
      </div>
    </section>

    <section class="bg-tertiary py-6 text-center text-white">
      <div class="container">
        <p>{{ $lang("desc") }}</p>
      </div>
    </section>

    <section class="section-14 py-6">
      <div class="container">
        <h4 class="mb-5 text-primary text-center max-w-5">
          {{ $lang("features.title") }}
        </h4>

        <div class="row text-center">
          <div
            class="col-lg"
            v-for="i of $lang('features.items')"
            :key="i.title"
          >
            <div class="card mb-4" :class="`card-${i.color}`">
              <div
                class="img-container"
                :style="`background-image: url('${i.img}')`"
              />
              <div class="card-body">
                <h5>{{ i.title }}</h5>
                <h4 class="mb-4">{{ i.days }}</h4>
                <button class="btn btn-outline-medium-shade">
                  {{ i.category }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-9">
            <h4 class="my-5 text-primary">{{ $lang("features.subtitle") }}</h4>
          </div>
        </div>

        <ul class="ml-2 ml-md-5" v-html="$lang('features.requirements')" />

        <div class="row my-6 text-left">
          <div class="col-lg-4 visa-05">
            <div
              class="img-container img-container-rounded h-100"
              style="background-image: url('/imgs/visa-05.png')"
            ></div>
          </div>
          <div
            class="col-lg-7 text-medium py-lg-4"
            v-html="$lang('features.desc')"
          />
        </div>

        <button
          class="btn btn-primary px-5 mt-5 mx-auto"
          @click="$router.push('/contact-us')"
        >
          {{ $lang("features.button") }}
        </button>
      </div>
    </section>
  </div>
</template>
